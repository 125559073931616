<template>
    <div class="video">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" autoplay loop muted ></video>
        </div>

        <div class="Speaker">
            <div class="title">学校信息</div>
            <div class="contentBox">
                <div class="SpeakerBox" v-for="(item , index) in videoList" :key="index"  @click="showVideo(item)">
                    <video @play="videoPlay" class="videoPlayer"  :poster="item.cover_pc" :src="item.url" controlslist="nodownload" disablePictureInPicture></video>
                    <div class="content">
                        <img :src="item.icon" alt="">
                        <div class="info">
                            <div class="name">{{item.name}}</div>
                            <div class="address">{{item.address}}</div>
                            <div class="rank">U.S.NEWS：#{{item.rank}}</div>
                        </div>
                    </div>
                    <div class="top">
                        <div class="name_en">{{item.name_en}}</div>
                        <img src="./../../assets/img/videoPlay.png" alt="" class="videoPlay">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {banner , get_sv} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            videoList : [],
            load : false,
            playTarget : ''
        }
    },
    mounted(){
        banner({'seat' : 3}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
        this.getVideoList()
    },
    methods : {
        videoPlay(e){
            var videoList = document.querySelectorAll('.videoPlayer')
            for(var i = 0 ; i < videoList.length ; i ++){
                if(!videoList[i].paused && videoList[i] != e.target){
                    videoList[i].pause()
                    break
                }
            }
        },
        getVideoList(){
            get_sv().then(res => {
                this.videoList = res.data.data
            })
        },
        showVideo(item){
            if(!item.url){
                this.$message({ message: '敬请期待～', type: 'success' })
            }else{
                var e = window.event
                this.playTarget = e.currentTarget
                if(this.playTarget.children[0].getAttribute('controls')){
                    return
                }
                this.playTarget.children[0].setAttribute('controls' , true)
                // this.playTarget.children[1].style.display = 'none'
                setTimeout(() => {
                    this.playTarget.children[0].play()
                } , 100)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.video{
    background: #1C1C1C;
    .Speaker{
        width: 1200px;
        margin: 64px auto 0;
        text-align: center;
        padding-bottom: 150px;
        overflow: hidden;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 40px;
        }
        .SpeakerBox{
            width: 560px;
            height: 490px;
            background: #363636;
            border-radius: 16px;
            position: relative;
            overflow: hidden;
            float: left;
            margin-top: 80px;
            cursor: pointer;
            video{
                width: 100%;
                height: 330px;
            }
            .top{
                position: absolute;
                top: 20px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 32px 0 26px;
                .name_en{
                    font-size: 30px;
                    color: #FFFFFF;
                    line-height: 35px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    text-align: left;
                }
                img{
                    width: 72px;
                    height: 72px;
                }
            }
            .content{
                display: flex;
                align-items: center;
                padding: 19px;
                img{
                    width: 122px;
                    height: 122px;
                }
                .info{
                    margin-left: 27px;
                    text-align: left;
                }
                .name{
                    font-size: 30px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 35px;
                }
                .address{
                    font-size: 24px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #AAAAAA;
                    line-height: 28px;
                    margin-top: 3px;
                }
                .rank{
                    width: 187px;
                    height: 34px;
                    background: #7FDEE4;
                    border-radius: 8px;
                    font-size: 24px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #333333;
                    line-height: 34px;
                    margin-top: 9px;
                    text-align: center;
                }
            }
        }
        .SpeakerBox:nth-of-type(2n){
            margin-left: 80px;
        }
        .SpeakerBox:nth-of-type(1){
            margin-top: 48px;
        }
        .SpeakerBox:nth-of-type(2){
            margin-top: 48px;
        }
    }
}

</style>