<template>
    <div class="Mjoin">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <!-- <div class="videoBoxtitle">{{ data.title }}</div> -->
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="joinContent">
            <div class="stepWrapper">
                <div class="name">注册步骤：</div>
                <div class="stepList">
                        <div class="lineListBox">
                            <img src="./../../assets/img/clock.png" class="clockImg"/>
                            <span>微信扫描下方二维码</span>
                        </div>
                </div>
                <div class="stepList">
                    <div class="lineCut"></div>
                        <div class="lineListBox">
                            <img src="./../../assets/img/clock.png" class="clockImg"/>
                            <span>小程序点击报名按钮</span>
                        </div>
                </div>
                <div class="stepList">
                    <div class="lineCut"></div>
                        <div class="lineListBox">
                            <img src="./../../assets/img/clock.png" class="clockImg"/>
                            <span>请准确填写报名表格</span>
                        </div>
                </div>
                <div class="stepList">
                    <div class="lineCut"></div>
                        <div class="lineListBox">
                            <img src="./../../assets/img/clock.png" class="clockImg"/>
                            <span>添加官方微信小助手</span>
                        </div>
                </div>
            </div>
            <div class="codeBox">
                <img src="./../../assets/img/Mvxcode.png" class="Mvxcode"/>
                <div class="code2">
                    <span>小程序</span>
                    <img src="./../../assets/img/Mvxcode2.png" class="Mvxcode2"/>
                    <span>小助手</span>
                </div>
                <img src="./../../assets/img/Micon.png" class="Micon"/>
            </div>
        </div>
    </div>
</template>
<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false,
        }
    },
    mounted(){
        banner({'seat' : 2,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        
    },
}
</script>
<style lang="less" scoped>
.Mjoin{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .joinContent {
        margin-top: 24px;
        .stepWrapper{
            width: 331px;
            height: 247px;
            background: #333333;
            border-radius: 8px;
            margin: 24px auto 0;
            overflow: hidden;
            .stepList{
                margin-left: 75px;
                .lineCut{
                    width: 2px;
                    height: 13px;
                    background: #fff;
                    margin: 8px;
                }
                .lineListBox{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-family: YouSheBiaoTiHei;
                    color: #7FDDE4;
                    line-height: 23px;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 12px;
                    }
                }
            }
            .name{
                font-size: 18px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 23px;
                margin: 16px auto 16px;
            }
        }
        .codeBox{
            width: 331px;
            height: 392px;
            background: #333333;
            border-radius: 8px;
            margin: 14px auto 0;
            position: relative;
            .Micon{
                width: 166px;
                height: 174px;
                position: absolute;
                left: 22px;
                bottom: 0;
            }
            .Mvxcode{
                width: 267px;
                height: 150px;
                margin: 16px auto 0;
            }
            .code2{
                width: 267px;
                margin: 4px auto 0;
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 20px;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                z-index: 1;
                position: relative;
                .Mvxcode2{
                    width: 151px;
                    height: 151px;
                    margin-top: 12px;
                    margin-bottom: 4px;
                    display: block;
                }
                span{
                    display: block;
                    width: 151px;
                    text-align: center;
                }
            }
        }
        .title {
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #ffffff;
            line-height: 31px;
        }
        .type {
        margin-top: 16px;
        .typeBox {
            width: 331px;
            height: 232px;
            position: relative;
            overflow: hidden;
            margin: 24px auto 0;
            img {
            width: 100%;
            height: 100%;
            }
            .titleSpan {
            padding: 0 14px;
            height: 50px;
            background: rgba(56, 56, 56, 0.2);
            border: 1px solid #ffffff;
            font-size: 32px;
            font-family: YouSheBiaoTiHei;
            color: #ffffff;
            line-height: 48px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            }
        }
        .typeBox:active {
            img {
            transform: scale(1.1);
            transition: 0.2s;
            }
            .model {
            color: #fff;
            }
        }
        .typeBox:nth-of-type(1) {
            margin-top: 0;
        }
        }
    }
}
</style>