<template>
    <div class="Mvideo">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <!-- <div class="videoBoxtitle">{{ data.title }}</div> -->
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>

        <div class="contentBox">
            <div class="SpeakerBox" v-for="(item , index) in videoList" :key="index"  @click="playVideo(item)">
                <img class="videoPlayer" :src="item.cover_pc" alt="">
                <!-- <video class="videoPlayer"  :poster="item.cover_pc" :src="item.url" controlslist="nodownload" disablePictureInPicture></video> -->
                <div class="content">
                    <img :src="item.icon" alt="">
                    <div class="info">
                        <div class="name">{{item.name}}</div>
                        <div class="address">{{item.address}}</div>
                        <div class="rank">U.S.NEWS：#{{item.rank}}</div>
                    </div>
                </div>
                <div class="top">
                    <div class="name_en">{{item.name_en}}</div>
                    <img src="./../../assets/img/videoPlay.png" alt="" class="videoPlay">
                </div>
            </div>
        </div>

        <div class="videoModel" v-if="videoFlag" @click.stop.capture="closeVideo">
            <video :src="videoInfo.url" controls autoplay controlslist="nodownload" disablePictureInPicture></video>
        </div>

    </div>
</template>


<script>
import {banner , get_sv} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false,
            videoList : [],
            Current : 0,
            videoInfo : {},
            videoFlag : false
        }
    },
    mounted(){
        banner({'seat' : 3,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
        this.getVideoList()
    },
    methods : {
        closeVideo(e){
            if(e.target.className == 'videoModel'){
                this.videoFlag = false
            }
        },
        playVideo(item){
            if(item.url){
                this.videoInfo = item
                this.videoFlag = true
            }else{
                this.$message({ message: '敬请期待～', type: 'success' })
            }
        },
        getVideoList(){
            get_sv().then(res => {
                this.videoList = res.data.data
            })
        },
    }
}
</script>

<style lang="less" scoped>
.Mvideo{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .videoModel{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: rgba(28, 28, 28, 0.5);
        video{
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
        }
    }
    .contentBox{
        margin: 0 auto;
    }
    .SpeakerBox{
        background: #363636;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        margin: 24px 22px 0;
        cursor: pointer;
        .videoPlayer{
            width: 100%;
        }
        .top{
            position: absolute;
            top: 15px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 16px 0 16px;
            .name_en{
                font-size: 18px;
                color: #FFFFFF;
                line-height: 25px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                text-align: left;
            }
            img{
                width: 48px;
                height: 48px;
            }
        }
        .content{
            display: flex;
            align-items: center;
            padding: 12px;
            img{
                width: 72px;
                height: 72px;
            }
            .info{
                margin-left: 16px;
                text-align: left;
            }
            .name{
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 25px;
            }
            .address{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #AAAAAA;
                line-height: 20px;
                margin-top: 2px;
            }
            .rank{
                width: 112px;
                height: 20px;
                background: #7FDEE4;
                border-radius: 3px;
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #333333;
                line-height: 20px;
                margin-top: 5px;
                text-align: center;
            }
        }
    }
}
</style>