<template>
  <div class="assembly">
    <div class="videoBox">
      <div class="model" :class="load ? 'blackModel' : ''"></div>
      <div class="title">
        {{ $store.state.language.type == 1 ? data.title : data.title_en }}
      </div>
      <video :src="data.url" autoplay loop muted></video>
    </div>
    <div class="info">
      <div class="box1">
        <img src="./../../assets/img/mun.png" alt="" class="img1" />
      </div>
      <div class="type-name">北美常春藤名校信息分享会</div>
    </div>

    <div class="theme">
      <div class="title">
        <div class="text">深圳场-<span class="color">深圳国际交流书院分享会</span> </div>
        <div class="color text">2024年1月7日</div>
      </div>
      <div class="content">
        <video
          class="contentVideo"
          :src="theme.url"
          :poster="theme.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @ended="videoEnd"
        ></video>
      </div>
    </div>

    <div class="tese">
      <span class="title">活动特色</span>
      <el-carousel
        ref="carousel"
        class="carousel"
        arrow="never"
        :interval="10000"
        indicator-position="outside"
      >
        <el-carousel-item>
          <img src="./../../assets/img/tese.png" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="tipBox">
        <div class="tip">
          <div class="circle"></div>
          <div class="tipInfo">掌握一手藤校信息</div>
        </div>
        <div class="tip">
          <div class="circle"></div>
          <div class="tipInfo">了解多元化背景提升</div>
        </div>
        <div class="tip">
          <div class="circle"></div>
          <div class="tipInfo">了解藤校学生有哪些特征</div>
        </div>
        <div class="tip">
          <div class="circle"></div>
          <div class="tipInfo">感受美国藤校校园生活</div>
        </div>
        <div class="tip">
          <div class="circle"></div>
          <div class="tipInfo">现场问题针对性答疑解惑</div>
        </div>
      </div>
    </div>
    <div class="tese">
      <span class="title">活动目的</span>
      <el-carousel
        ref="carousel"
        class="carousel"
        arrow="never"
        :interval="10000"
        indicator-position="outside"
      >
        <el-carousel-item>
          <img src="./../../assets/img/mudi.png" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="desc">
        我们深知在本科申请时，由于信息不对称的原因，每年都导致不少同学们与心仪学校失之交臂。所以在本次的活动中，CollegeDirect团队将为粤港澳大湾区地区的本科申请者们带来最详细、最真实、最多样的优质信息源，帮助同学们在美国本科申请的过程中少走弯路，避免因为信息不对称的原因留下申请季的遗憾。
      </div>
      <div class="desc">
        同学们将有机会和学长学姐当面交流学校生活的各种细节：宿舍，选课，食堂，生活设施等等。和在校生的交流将是同学们了解学校真实学习生活环境的最好机会，并为选校做好最充分的准备。
      </div>
    </div>
    <div class="tese">
      <span class="title"
        ><span class="color">CollegeDirect</span>主办团队</span
      >
      <el-carousel
        ref="carousel"
        class="carousel"
        arrow="never"
        :interval="10000"
        indicator-position="outside"
      >
        <el-carousel-item>
          <img src="./../../assets/img/HomeTeam.png" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="desc">
        CollegeDirect在校生团队成员，皆是<span class="color">北美顶级高校</span
        >（常春藤八校以及其他TOP50名校）的在校生，或是已经毕业的学长、学姐。
      </div>
      <div class="desc">
        本次在深国交的交流活动中，我们将邀请<span class="color"
          >耶鲁大学，康奈尔大学，哥伦比亚大学</span
        >以及<span class="color">宾夕法尼亚大学</span
        >的嘉宾进行现场分享，其中包括<span class="color"
          >中国区宾夕法尼亚大学校友面试官</span
        >，更多神秘藤校嘉宾，敬请期待～
      </div>
    </div>

    <div class="speaker">
      <div class="titleName">
        <span>分享嘉宾阵容</span>
      </div>

      <div class="wrapper">
        <el-carousel
          ref="carousel"
          class="carousel"
          arrow="never"
          :interval="10000"
          indicator-position="outside"
        >
          <el-carousel-item
            v-for="page in Math.ceil(talker.length / 3)"
            :key="page"
          >
            <div class="partnerBox">
              <div class="speakerBox" v-if="talker[3 * (page - 1)]">
                <img :src="talker[3 * (page - 1)].avatar" alt="" />
              </div>
              <div class="speakerBox center" v-if="talker[3 * (page - 1) + 1]">
                <img :src="talker[3 * (page - 1) + 1].avatar" alt="" />
              </div>
              <div class="speakerBox" v-if="talker[3 * (page - 1) + 2]">
                <img :src="talker[3 * (page - 1) + 2].avatar" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="sort">（排名不分先后）</div>
      <div class="schedule">
        <div class="title">
          <span>活动日程</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="timeBox">
              <div class="time">
                <div
                  class="timelist"
                  v-for="(item, index) in step[0]"
                  :key="index"
                >
                  <div class="lineCut" v-if="index != 0"></div>
                  <div class="listBox">
                    <div class="clock">
                      <img src="./../../assets//img//clock.png" alt="" />
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="stepName">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="timeBox">
              <div class="time">
                <div
                  class="timelist"
                  v-for="(item, index) in step[1]"
                  :key="index"
                >
                  <div class="lineCut" v-if="index != 0"></div>
                  <div class="listBox">
                    <div class="clock">
                      <img src="./../../assets//img//clock.png" alt="" />
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="stepName">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="place">
        <div class="title">
          <span>活动位置</span>
        </div>
        <el-carousel
          ref="carousel"
          class="carousel"
          arrow="never"
          :interval="10000"
          indicator-position="outside"
        >
          <el-carousel-item v-for="(item, index) in mapList" :key="index">
            <div class="content">
              <img :src="item" alt="" class="img" />
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="placeTip">
          <img src="./../../assets/img/placeTip.png" alt="" />
          <span>深圳国际交流书院</span>
        </div>
      </div>
      <div class="partner">
        <div class="partnertitle">合作伙伴</div>
        <div class="parterContent">
          <div class="wrapperBox">
            <div class="titleBox">
              <div class="title">{{ configData.parter1 }}</div>
            </div>
            
            <el-carousel
              ref="carousel"
              class="carousel"
              arrow="never"
              :interval="10000"
              indicator-position="outside"
            >
              <el-carousel-item
                v-for="(item, index) in parterList"
                :key="index"
              >
                <div class="content">
                  <img :src="item" alt="" class="img" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="wrapperBox">
            <div class="titleBox">
              <div class="title">BetterWorld</div>
            <div class="title">全球青少年模拟联合国</div>
            </div>
            
            <el-carousel
              ref="carousel"
              class="carousel"
              arrow="never"
              :interval="10000"
              indicator-position="outside"
            >
              <el-carousel-item v-for="(item, index) in parter" :key="index">
                <div class="content">
                  <img :src="item" alt="" class="img" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banner, video, presidium, partner } from "./../../api/api";
import { config } from "./../../utils/config";
export default {
  data() {
    return {
      data: {
        title: "",
        describe: "",
        url: "",
      },
      load: false,
      mapList: [],
      parterList: [],
      parter: [],
      talker: [],
      importantList: [],
      theme: {},
      configData: {
        textName: {},
      },
      step: [
        [
          {
            time: "13:00-14:00",
            name: "签到入场",
          },
          {
            time: "14:00-14:20",
            name: "开幕仪式",
          },
          {
            time: "14:20-15:00",
            name: "藤校在校生分享",
          },
          {
            time: "15:00-15:20",
            name: "藤校校友面试官分享",
          },
        ],
        [
          {
            time: "15:20-16:20",
            name: "圆桌论坛",
          },
          {
            time: "16:20-17:00",
            name: "现场问答",
          },
          {
            time: "17:00-17:30",
            name: "活动散场",
          },
        ],
      ],
      eb_price_time: {
        day: "00",
        hour: "00",
        min: "00",
        sec: "00",
        time: 0,
      }, //早鸟价倒计时
    };
  },
  mounted() {
    this.mapList = JSON.parse(localStorage.config).activity_address.value;
    
    if (this.$store.state.language.type == 1) {
      this.configData = config.assembly.chinese;
    } else {
      this.configData = config.assembly.english;
    }
    banner({ seat: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.data = res.data.data[0];
        // console.log(this.data)
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          setTimeout(() => {
            this.load = true;
          }, 800);
        } else {
          this.load = true;
        }
        var eb_price_Defer =
          (JSON.parse(localStorage.config).eb_price_time.value -
            res.data.time * 1000) /
          1000;
        if (eb_price_Defer > 0) {
          this.eb_price_time.time = eb_price_Defer;
          setInterval(() => {
            this.ebPriceDeferFn();
          }, 1000);
        }
      } else {
        this.$message.error(res.data.msg);
      }
    });
    presidium().then((res) => {
      if (res.data.code === "10000") {
        this.talker = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    partner({ device: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.parter = res.data.data["1"];
        this.parterList = res.data.data["2"];
        this.importantList = res.data.data["3"];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //大会主题
    video({ type: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
  methods: {
    goAudience() {
      this.$router.push("/audience");
    },
    videoEnd() {
      let myVideo = document.querySelector(".contentVideo");
      myVideo.currentTime = 0.5;
    },
    //早鸟价倒计时
    ebPriceDeferFn() {
      var time = this.eb_price_time.time;

      let daydiff = 24 * 60 * 60;
      let hoursdiff = 60 * 60;
      let minutesdiff = 60;
      let day = Math.floor(time / daydiff); //天数
      let hour = Math.floor((time - day * daydiff) / hoursdiff);
      let min = Math.floor(
        (time - day * daydiff - hour * hoursdiff) / minutesdiff
      );
      let sec = Math.floor(
        time - day * daydiff - hour * hoursdiff - min * minutesdiff
      );
      if (day < 10) {
        this.eb_price_time.day = "0" + day;
      } else {
        this.eb_price_time.day = day;
      }
      if (hour < 10) {
        this.eb_price_time.hour = "0" + hour;
      } else {
        this.eb_price_time.hour = hour;
      }
      if (min < 10) {
        this.eb_price_time.min = "0" + min;
      } else {
        this.eb_price_time.min = min;
      }
      if (sec < 10) {
        this.eb_price_time.sec = "0" + sec;
      } else {
        this.eb_price_time.sec = sec;
      }
      this.eb_price_time.time -= 1;
    },
  },
  watch: {
    "$store.state.language": function () {
      if (this.$store.state.language.type == 1) {
        this.configData = config.assembly.chinese;
      } else {
        this.configData = config.assembly.english;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.assembly {
  background: #1c1c1c;

  .partner {
    width: 1200px;
    margin: 120px auto 0;
    overflow: hidden;
    padding-bottom: 151px;

    .partnertitle {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }

    .title {
      width: 295px;
      font-size: 32px;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .titleBox{
      height: 84px;
    }
    .wrapperBox:nth-of-type(1) .titleBox{
      .title{
        line-height: 84px;
      }
    }
    .parterContent {
      margin-top: 32px;
      display: flex;
      justify-content: space-evenly;
    }

    .carousel {
      width: 384px;
      height: 430px;
      margin-top: 30px;

      /deep/ .el-carousel__container {
        height: 403px;
        background: #000;
        border-radius: 8px;

        .content {
          width: 100%;
          height: 100%;
          border-radius: 8px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }

    /deep/ .el-carousel__indicator--horizontal {
      padding: 15px 4px 0;
    }
  }

  .schedule {
    width: 1200px;
    margin: 120px auto 0;
    overflow: hidden;

    .content {
      margin-top: 35px;
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        margin-left: 81px;
      }

      .right {
        margin-left: 140px;
        display: flex;
        flex-direction: column;
      }

      .timeBox {
        .time {

          .timelist {
            .lineCut {
              width: 1px;
              height: 34px;
              opacity: 0.6;
              border: 1px solid #ffffff;
              margin: 9px 0 5px 11px;
            }

            .listBox {
              display: flex;
              align-items: center;

              .stepName {
                font-size: 20px;
                font-family: "AlibabaPuHuiTi_2_55_Regular";
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
              }
            }

            .clock {
              font-size: 24px;
              font-family: YouSheBiaoTiHei;
              color: #7fdee4;
              line-height: 31px;
              display: flex;
              align-items: center;
              width: 314px;

              img {
                width: 24px;
                height: 24px;
                margin-right: 40px;
              }
            }
          }
        }

        .day {
          font-size: 28px;
          font-family: YouSheBiaoTiHei;
          color: #7fdee4;
          line-height: 36px;
        }
      }

      .second {
        margin-top: 119px;
      }
    }

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }

  .wrapper {
    height: 500px;
    margin-top: 32px;

    .carousel {
      height: 525px;

      /deep/ .el-carousel__container {
        height: 500px;
      }
    }

    /deep/ .el-carousel__indicator--horizontal {
      padding: 15px 4px 0;
    }

    .speakerBox {
      text-align: center;
      width: 384px;
      float: left;
      height: 500px;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }

    .center {
      margin: 0 24px 0;
    }
  }

  .speaker {
    width: 1200px;
    margin: 120px auto 0;

    .sort {
      font-size: 19px;
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 29px;
      margin-top: 41px;
      text-align: center;
    }

    .list {
      width: 1200px;
      margin: 50px auto 0;

      img {
        width: 100%;
        height: 440px;
        border-radius: 8px;
      }

      .text {
        font-size: 19px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #ffffff;
        line-height: 26px;
        margin-top: 10px;
        text-align: center;
      }
    }

    .titleName {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }

    .place {
      width: 1200px;
      margin: 120px auto 0;
      overflow: hidden;

      .placeTip {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;

        img {
          width: 16px;
          height: 24px;
        }

        span {
          font-size: 20px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #ffffff;
          line-height: 28px;
          margin-left: 24px;
        }
      }

      .carousel {
        height: 790px;
        margin: 32px auto 0;

        /deep/ .el-carousel__container {
          height: 756px;
        }
      }

      .content {
        width: 100%;
        height: 756px;

        .img {
          width: 100%;
          height: 100%;
          background: #9f9f9f;
          border-radius: 8px;
        }
      }

      .title {
        font-size: 34px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .theme {
    width: 1200px;
    margin: 20px auto 0;

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      .text{
        margin-top: 20px;
      }
    }

    .color {
      color: #7fdee4;
    }

    .content {
      width: 1200px;
      height: 674px;
      border-radius: 8px;
      margin: 32px auto 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 120px;

    .box1 {
      display: flex;
      align-items: center;

      .img1 {
        width: 403px;
        height: 93px;
      }
    }

    .type-name {
      font-size: 34px;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      margin-top: 32px;
    }
  }

  .videoBox {
    // background-image: url('./../../assets/img/assemblyBanner.png');
    video {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
    }
  }
}
.tese {
  width: 1200px;
  margin: 119px auto 0;
  text-align: center;
  .title {
    font-size: 34px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
  }
  .carousel {
    height: 630px;
    margin-top: 32px;
    /deep/ .el-carousel__container {
      height: 600px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .tipBox {
    font-size: 20px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: normal;
    color: #d1d1d1;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    .tip {
      margin-top: 32px;
    }
    .tip:nth-of-type(3n + 2) {
      width: 488px;
    }
    .tip:nth-of-type(3n + 1) {
      width: 471px;
    }
    .circle {
      width: 12px;
      height: 12px;
      background: #ffffff;
      border-radius: 50%;
      margin-right: 12px;
    }
    .tip {
      display: flex;
      align-items: center;
    }
  }
  .desc {
    width: 1200px;
    font-size: 20px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: normal;
    color: #d1d1d1;
    line-height: 23px;
    margin-top: 24px;
    text-align: left;
  }
}
</style>