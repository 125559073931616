<template>
	<div class="home">
		<div class="head">
			<div @click="goHome" class="homeNav">
				<img src="./../../assets/img/MUNlOGO.png" alt="" >
			</div>
			<div class="nav">
				<!-- <div class="listItem" :class="routeName == 'about' ? 'active' : '' " @click="goAbout">{{ configData.root1 }}</div> -->
				<div class="listItem" :class="routeName == 'assembly' ? 'active' : '' ">
					<span @click="goAssembly">{{ configData.root2 }}</span>
				</div>
				
				<div class="listItem" :class="(routeName == 'join' || routeName == 'weiyuan' || routeName == 'preparation' || routeName == 'food' || routeName == 'city') ? 'active' : '' " >
					<span @click="goJoin">{{ configData.root3 }}</span>
					<!-- <i :class=" open ? 'el-icon-arrow-up' :  'el-icon-arrow-down'" @click.stop="open = !open"></i>
					<div class="childRoot" v-if="open">
						<div class="root"  :class="routeName == 'weiyuan' ? 'active' : '' " @click="nextPage(5)">委员会</div>
						<div class="root"  :class="routeName == 'preparation' ? 'active' : '' " @click="nextPage(6)">参会准备</div>
						<div class="root"  :class="routeName == 'food' ? 'active' : '' " @click="nextPage(7)">衣食住行</div>
						<div class="root"  :class="routeName == 'city' ? 'active' : '' " @click="nextPage(8)">城市探索</div>
					</div> -->
				</div>
				<div class="listItem" :class="routeName == 'video' ? 'active' : '' " @click="goVideo">{{ configData.root4 }}</div>
				<div class="listItem" :class="routeName == 'contact' ? 'active' : '' " @click="goContact">{{ configData.root5 }}</div>
				<div class="listItem" @click="changeLanguage">中文/English</div>
			</div>
			<!-- <div class="login" @click="loginWX" v-if="loginStatus == 1">登录</div>
			<div class="center" v-if="loginStatus == 2">
				<img src="./../../assets/img/center.png" alt="" class="centerImg">
				<img src="./../../assets/img/down.png" alt="" class="down">
			</div> -->
		</div>
		<router-view></router-view>
		<div class="kefu" @click="showCode">
			<img src="./../../assets/img/kefu.png" alt="" class="kefuImg">
			<img src="./../../assets/img/kefucode.png" alt="" class="kefucodeImg" v-if="wxcode">
		</div>
		<div class="footer">
			<img src="./../../assets/img/MUNlOGO.png" alt="" class="img">
			<div class="textBox">
				<div class="text">
					<a href="https://beian.miit.gov.cn">琼ICP备2023003747号-4</a>
					<span> ｜ </span>
					<img src="./../../assets/img/beian.png" alt="" class="beian">
					<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46020402000177" style="display:inline-block;"><img src="" style="float:left;"/>琼公网安备46020002000129号</a>
					<span @click="goContact" class="contact"> ｜ 联系我们</span></div>
				<div class="text2">@ 2023 Copyright-CollegeDirect在校生</div>
			</div>
		</div>
	</div>
</template>

<script>
import {config} from './../../utils/config'
import {oauthLogin} from './../../api/api'
export default {
	data(){
		return {
			routeName : '',
			open : false,
			configData : {},
			loginStatus : 1,//登陆状态，1：未登录，2：已登录
			wxcode : false,
		}
	},
	methods : {
		showCode(){
			this.wxcode = !this.wxcode
		},
		//微信登录
        loginWX(){
			// + this.$route.name
            var appid = 'wx92aa6631c27949f7'
            var REDIRECT_URI = 'https%3A%2F%2Fwww.collegedirectedu.com%2F%23%2Fbeijing'
            var code = 'code'
            var state = 'weixin'
            window.location = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + appid + '&redirect_uri=' + REDIRECT_URI + '&response_type=' +code+ '&scope=snsapi_login' + '&state=' + state
        },
		changeLanguage(){
			var obj = {}
			if(this.$store.state.language.type == 1){
				obj.type = 2
			}else{
				obj.type = 1
			}
			this.$store.commit('LanguageFn' , obj)
		},
		goAssembly(){
            this.$router.push('/assembly')
        },
		goHome(){
			this.$router.push('/assembly')
		},
		goVideo(){
			this.$router.push('/video')
		},
		goJoin(){
			this.$router.push('/join')
		},
		goAbout(){
			this.$router.push('/about')
		},
		goContact(){
			this.$router.push('/contact')
		},
		nextPage(type){
			if (type == 1) {//个人注册
				this.$router.push('/personRegister')
			} else if (type == 2) {//团队注册
				this.$router.push('/team')
			} else if (type == 3) {//志愿者注册
				this.$router.push('/volunteer')
			} else if (type == 4) {//主席团助理注册
				this.$router.push('/president')
			}else if (type == 5) {
				this.$router.push('/weiyuan')
			} else if (type == 6) {
				this.$router.push('/preparation')
			} else if (type == 7) {
				this.$router.push('/food')
			} else if (type == 8) {
				this.$router.push('/city')
			}
			this.open = false
		}
	},
	mounted(){
		document.addEventListener('click' , () => {
			this.open = false
		})
		this.routeName = this.$route.name
		if(this.$store.state.language.type == 1){
			this.configData = config.home.chinese
		}else{
			this.configData = config.home.english
		}
		
        if(this.$route.query.code){
            var obj = {}
            if(this.$route.query.code){//微信登陆
                obj.oauth_code = this.$route.query.code
            }
            // obj.device = 1
            oauthLogin(obj).then(res => {
                if(res.data.code === '10000'){
					localStorage.setItem('MUNtoken', res.data.data.auth)
					localStorage.setItem('user_id', res.data.data.user_id)
					this.loginStatus = 2
					window.location.href = window.location.href.replace('code' , 'Haslogin')
                }else{
					this.$message.error(res.data.msg)
                }
            })
        }
	},
	watch : {
		"$route" : function (){
			this.routeName = this.$route.name
			this.wxcode = false
		},
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.home.chinese
			}else{
				this.configData = config.home.english
			}
		}
	}
}
</script>
<style lang="less" scoped>
.home{
	background: #000000;
	min-width: 1270px;
	position: relative;
	z-index: 9;
	height: 100vh;
	.kefu{
		width: 56px;
		height: 56px;
		background: #7FDDE4;
		border-radius: 50%;
		position: fixed;
		right: 29px;
		bottom: 91px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		.kefuImg{
			width: 32px;
			height: 32px;
		}
		.kefucodeImg{
			position: absolute;
			bottom: 41px;
			right: 51px;
			width: 158px;
			height: 277px;
		}
	}
	.center{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 163px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		.centerImg{
			width: 48px;
			height: 48px;
		}
		.down{
			width: 12px;
			height: 8px;
			margin-left: 13px;
		}
	}
	.footer{
		height: 152px;
		position: relative;
		z-index: 9;
		background: #000000;
		.img{
			width: 504px;
			height: 117px;
			margin-top: 18px;
			margin-left: 86px;
			float: left;
		}
		.contact{
			cursor: pointer;
		}
		.textBox{
			font-size: 16px;
			font-family: AlibabaPuHuiTi_2_55_Regular;
			color: #FFFFFF;
			line-height: 20px;
			float: right;
			text-align: right;
			margin-top: 49px;
			white-space: nowrap;
			margin-right: 124px;
			a{
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				color: #FFFFFF;
				line-height: 20px;
				text-decoration:none;
			}
			.beian{
				width: 18px;
				height: 20px;
				vertical-align: bottom;
				margin-right: 8px;
			}
		}
		.text2{
			margin-top: 11px;
		}
	}
	.head{
		width: 100%;
		min-width: 1270px;
		// height: 144px;
		position: absolute;
		top: 0;
		z-index: 99;
		.login{
			width: 105px;
			height: 45px;
			background: #7FDEE4;
			border-radius: 24px;
			font-size: 22px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 45px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 147px;
			cursor: pointer;
			text-align: center;
		}
		.nav{
			top: 57px;
			position: absolute;
			right: 186px;
			.listItem{
				font-size: 22px;
				font-family: YouSheBiaoTiHei;
				color: #FFFFFF;
				line-height: 29px;
				float: left;
				margin-left: 22px;
				cursor: pointer;
				text-align: center;
				position: relative;
				i{
					position: absolute;
					font-size: 12px;
					line-height: 29px;
					right: 0;
					transition: 0.2s;
					width: 12px;
					text-indent: 0;
					color: #fff;
				}
				.childRoot{
					width: 127px;
					height: 202px;
					background: #000000;
					border-radius: 8px;
					border: 1px solid #FFFFFF;
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					color: #FFFFFF;
					line-height: 25px;
					text-align: center;
					text-indent: 0;
					margin-top: 5px;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					.root:hover{
						color: #7FDEE4;
					}
					.root{
						margin-top: 22px;
					}
					.root:nth-of-type(1){
						margin-top: 12px;
					}
					// .root:nth-last-of-type(1){
					// 	margin-top: 26px;
					// }
				}
				.RegisterRoot{
					height: 108px;
				}
			}
			.active{
				color: #7FDEE4;
			}
			.join{
				// width: 62px;
				margin-left: 20px;
				text-align: left;
				padding-right: 20px;
				position: relative;
			}
		}
		.homeNav{
			width: 504px;
			height: 117px;
			top: 8px;
			float: left;
			cursor: pointer;
			position: absolute;
			left: 86px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
