<template>
    <div class="join">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="contentBox">
            <div class="headTitle">注册报名</div>
            <div class="registerStep">
                <img src="./../../assets/img/registerStep1.png"  class="registerStep1"  />
                <div class="registerCode">
                    <div class="stepBox">
                        <div class="name">注册步骤：</div>
                        <div class="stepWrapper">
                            <div class="stepList">
                                    <div class="lineListBox">
                                        <img src="./../../assets/img/clock.png" class="clockImg"/>
                                        <span>微信扫描右侧小程序</span>
                                    </div>
                            </div>
                            <div class="stepList">
                                <div class="lineCut"></div>
                                    <div class="lineListBox">
                                        <img src="./../../assets/img/clock.png" class="clockImg"/>
                                        <span>小程序点击报名按钮</span>
                                    </div>
                            </div>
                            <div class="stepList">
                                <div class="lineCut"></div>
                                    <div class="lineListBox">
                                        <img src="./../../assets/img/clock.png" class="clockImg"/>
                                        <span>请准确填写报名表格</span>
                                    </div>
                            </div>
                            <div class="stepList">
                                <div class="lineCut"></div>
                                    <div class="lineListBox">
                                        <img src="./../../assets/img/clock.png" class="clockImg"/>
                                        <span>添加官方微信小助手</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="codeBox">
                        <div class="code">
                            <img src="./../../assets/img/code1.png"  class="code1"  />
                            <span>小程序</span>
                        </div>
                        <div class="code">
                            <img src="./../../assets/img/code2.png"  class="code2"  />
                            <span>小助手</span>
                        </div>
                    </div>
                    <img src="./../../assets/img/icon.png"  class="icon"  />
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
        }
    },
    mounted(){
        if(this.$route.query.bottom){
            var element = document.getElementById('app'); // 获取需要滚动的节点
            element.scrollTop = 10000000; // 滚动到距离顶部100像素的位置
        }
        banner({'seat' : 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        
    },
}
</script>


<style lang="less" scoped>
.join{
    padding-bottom: 150px;
    background: #1C1C1C;
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
.headTitle{
    font-size: 34px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    margin-top: 64px;
    text-align: center;
}
.registerStep{
	width: 1200px;
	height: 419px;
	margin: 48px auto 0;
	display: flex;
	align-items: center;
    position: relative;
	.registerCode{
		width: 850px;
		height: 419px;
		background: #333333;
		border-radius: 8px;
		display: flex;
		align-items: center;
	}
	.stepBox{
		margin-left: 72px;
		height: 100%;
		.name{
			font-size: 44px;
			line-height: 42px;
			font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
			font-weight: 400;
			color: #FFFFFF;
			margin-top: 35px;
		}
		.stepWrapper{
			margin-top: 35px;
			.lineCut{
				width: 1px;
				height: 34px;
				background: #fff;
				margin: 11px 12px;
			}
			.lineListBox{
				font-size: 24px;
				font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
				font-weight: 400;
				color: #7FDEE4;
				line-height: 28px;
				display: flex;
				align-items: center;
				.clockImg{
					width: 25px;
					height: 25px;
					margin-right: 40px;
				}
			}
		}
	}
	.registerStep1{
		width: 350px;
		height: 100%;
	}
    .codeBox{
        display: flex;
        margin-left: 50px;
        margin-top: 23px;
        .code{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img{
                width: 200px;
                height: 200px;
            }
            span{
                font-size: 20px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                font-weight: normal;
                color: #FFFFFF;
                line-height: 23px;
                margin-top: 8px;
            }
        }
        .code:nth-of-type(2){
            margin-left: 20px;
        }
    }
    .icon{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 97px;
        height: 102px;
    }
}
</style>