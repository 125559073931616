<template>
  <div class="Massembly">
    <div class="MvideoBox">
      <div class="model" :class="load ? 'blackModel' : ''"></div>
      <!-- <div class="videoBoxtitle">{{ data.title }}</div> -->
	  		<div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
      <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true"></video>
    </div>
    <div class="headInfo">
		<img src="./../../assets/img/mun.png" alt="" class="img1" />
		<div class="span1">北美常春藤名校信息分享会</div>
    </div>
    <div class="countDown">
      <div class="labelBox">
        <div class="countLabel">天</div>
        <div class="leftLeaf firstDay">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hundred ? "1" : "0" }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hundred ? "1" : "0" }}</span>
          </div>
          <div class="rotorLeaf">
            <div class="rotate">
              {{ countDownTime.hundred ? "1" : "0" }}
            </div>
            <div class="rotate">
              {{ countDownTime.hundred ? "1" : "0" }}
            </div>
          </div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.day1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.dayCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.dayType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.day1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.dayCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.day2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.dayCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.dayType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.day2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.dayCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">时</div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hour1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hourCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.hourType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.hour1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.hourCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hour2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hourCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.hourType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.hour2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.hourCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">分</div>
        <div class="circleBox">
          <div class="timeCut"></div>
          <div class="timeCut"></div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.min1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.minCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.minType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.min1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.minCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.min2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.minCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.minType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.min2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.minCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">秒</div>
        <div class="circleBox">
          <div class="timeCut"></div>
          <div class="timeCut"></div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.sec1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.secCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.secType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.sec1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.secCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.sec2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.secCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.secType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.sec2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.secCopy2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="theme">
		<div class="title">
			<div>深圳场</div>
			<div class="color">深圳国际交流书院分享会</div>
			<div class="color">2024年1月7日</div>
		</div>
		<div class="videoContent">
			<video
			class="contentVideo"
			:src="theme.url"
			:poster="theme.cover"
			controls
			controlslist="nodownload"
			disablePictureInPicture
			@ended="videoEnd"
			></video>
		</div>
    </div>
	<!-- 活动特色 -->
	<div class="tese">
		<div class="title">活动特色</div>
		<img src="https://www.collegedirectedu.com/uploads/wxapp/img/tese.png" mode=""/>
		<div class="tipBox">
			<div class="tipLine">
				<div class="tips">
					<div class="circle"></div>
					<span>掌握一手藤校信息</span>
				</div>
				<div class="tips">
					<div class="circle"></div>
					<span>感受美国藤校校园生活</span>
				</div>
			</div>
			<div class="tipLine">
				<div class="tips">
					<div class="circle"></div>
					<span>了解多元化背景提升</span>
				</div>
				<div class="tips">
					<div class="circle"></div>
					<span>现场问题针对性答疑解惑</span>
				</div>
			</div>
			<div class="tips">
				<div class="circle"></div>
				<span>了解藤校学生有哪些特征</span>
			</div>
		</div>
	</div>
	<!-- 活动目的 -->
	<div class="mudi">
		<div class="title">活动目的</div>
		<img src="https://www.collegedirectedu.com/uploads/wxapp/img/mudi.png" mode=""/>
		<div class="textContent">我们深知在本科申请时，由于信息不对称的原因，每年都导致不少同学们与心仪学校失之交臂。所以在本次的活动中，CollegeDirect团队将为粤港澳大湾区地区的本科申请者们带来最详细、最真实、最多样的优质信息源，帮助同学们在美国本科申请的过程中少走弯路，避免因为信息不对称的原因留下申请季的遗憾。</div>
		<div class="textContent">同学们将有机会和学长学姐当面交流学校生活的各种细节：宿舍，选课，食堂，生活设施等等。和在校生的交流将是同学们了解学校真实学习生活环境的最好机会，并为选校做好最充分的准备。</div>
	</div>
	<!-- 主办团队 -->
	<div class="mudi">
		<div class="title"><span class="color">CollegeDirect</span>主办团队</div>
		<img src="https://www.collegedirectedu.com/uploads/wxapp/img/Hometeam.png" mode=""/>
		<div class="textContent">CollegeDirect在校生团队成员，皆是<span class="color">北美顶级高校</span>（常春藤八校以及其他TOP50名校）的在校生，或是已经毕业的学长、学姐。</div>
		<div class="textContent">本次在深国交的交流活动中，我们将邀请<span class="color">耶鲁大学，康奈尔大学，哥伦比亚大学</span>以及<span class="color">宾夕法尼亚大学</span>的嘉宾进行现场分享，其中包括<span class="color">中国区宾夕法尼亚大学校友面试官</span>，更多神秘藤校嘉宾，敬请期待～</div>
	</div>

    <div class="speaker">
      <div class="titleName">
        <span>主席团阵容</span>
      </div>
      <div class="wrapper" @touchstart="touchstartFn" @touchend="touchendFn">
        <div class="dots">
          <div
            :class="Current1 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in talker"
            :key="index"
          ></div>
        </div>
        <el-carousel
          @change="change1"
          ref="carousel"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in talker" :key="index">
            <div class="speakerBox">
              <img :src="item.avatar" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      
    </div>
	<div class="schedule">
		<div class="title">活动日程</div>
		<div class="content">
			<div class="timelist" v-for="(item , index) in step" :key="index">
				<div class="lineCut" v-if="index != 0"></div>
				<div class="listBox">
					<div class="clock">
						<img src="https://www.collegedirectedu.com/uploads/wxapp/img/clock.png" alt=""></img>
						<span>{{ item.time }}</span>
					</div>
					<div class="stepName">{{ item.name }}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="place">
        <div class="title">
          <span>活动位置</span>
        </div>
        <div
          class="mapContent"
          @touchstart="touchstartFn3"
          @touchend="touchendFn3"
        >
          <div class="dots">
            <div
              :class="Current2 == index ? 'activeDots dot' : 'dot'"
              v-for="(item, index) in mapList"
              :key="index"
            ></div>
          </div>
          <el-carousel
            ref="carouselMap"
            @change="change2"
            class="carouselMap"
            :interval="5000"
            arrow="never"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item, index) in mapList" :key="index">
              <div class="content">
                <img :src="item" alt="" class="img" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="placeTip">
          <img src="./../../assets/img/placeTip.png" alt="" />
          <span>深圳国际交流书院</span>
        </div>
      </div>
    <div class="parterTitle">合作伙伴</div>
    <div class="partner">
      <div class="title">深圳国际交流书院</div>
      <div
        class="partnerBox"
        @touchstart="touchstartFn2"
        @touchend="touchendFn2"
      >
        <div class="dots">
          <div
            :class="Current2 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in parterList"
            :key="index"
          ></div>
        </div>
        <el-carousel
          @change="change2"
          ref="carouselParter2"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in parterList" :key="index">
            <div class="partnerItem">
              <img :src="item" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">BetterWorld全球青少年模拟联合国</div>
      <div
        class="partnerBox"
        @touchstart="touchstartFn4"
        @touchend="touchendFn4"
      >
        <div class="dots">
          <div
            :class="Current4 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in parter"
            :key="index"
          ></div>
        </div>
        <el-carousel
          ref="carouselParter4"
          @change="change4"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in parter" :key="index">
            <div class="partnerItem">
              <img :src="item" alt="" class="img" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  banner,
  video,
  presidium,
  partner,
} from "./../../api/api";
export default {
	data() {
		return {
		height: "252",
		data: {
			title: "",
			describe: "",
			video_url: "",
		},
		load: false,
		startMove: 0,
		startMove2: 0,
		startMove3: 0,
		startMove4: 0,
		startMove5: 0,
		Current1: 0,
		Current2: 0,
		Current3: 0,
		Current4: 0,
		Current5: 0,
		talkerImg: "",
		mapList: [],
		parterList: [],
		parter: [],
		talker: [],
		important_partner_mini: [],
		theme: {},
		step: [{
                time: '13:00-14:00',
                name: '签到入场',
            },
            {
                time: '14:00-14:20',
                name: '开幕仪式',
            },
            {
                time: '14:20-15:00',
                name: '藤校在校生分享',
            },
            {
                time: '15:00-15:20',
                name: '藤校校友面试官分享',
            },
            {
                time: '15:20-16:20',
                name: '圆桌论坛',
            },
            {
                time: '16:20-17:00',
                name: '现场问答',
            },
            {
                time: '17:00-17:30',
                name: '活动散场',
            },
        ],
		countDownTime: {
			time: "612",
			day1: "0",
			day2: "0",
			hour1: "0",
			hour2: "0",
			min1: "0",
			min2: "0",
			sec1: "0",
			sec2: "0",
			secCopy1: "0",
			secCopy2: "0",
			dayCopy1: "0",
			dayCopy2: "0",
			hourCopy1: "0",
			hourCopy2: "0",
			minCopy1: "0",
			minCopy2: "0",
			dayType1: false,
			hourType1: false,
			minType1: false,
			secType1: false,
			dayType2: false,
			hourType2: false,
			minType2: false,
			secType2: false,
			hundred: false,
		},
		};
	},
	mounted() {
		this.talkerImg = JSON.parse(localStorage.config).talker_mini.value[0];
		this.mapList = JSON.parse(localStorage.config).activity_address.value;
		banner({ seat: 1 ,'device': 2}).then((res) => {
		var timeDefer =
			(JSON.parse(localStorage.config).activity_date.value -
			res.data.time * 1000) /
			1000;
		if (timeDefer > 0) {
			this.countDownTime.time = timeDefer;
			setInterval(() => {
			this.countDownTimeFn();
			}, 1000);
		}
		if (res.data.code === "10000") {
			this.data = res.data.data[0];
			setTimeout(() => {
			this.load = true;
			}, 800);
		} else {
			this.$message.error(res.data.msg);
		}
		});
		presidium().then((res) => {
		if (res.data.code === "10000") {
			this.talker = res.data.data;
		} else {
			this.$message.error(res.data.msg);
		}
		});
		partner({ device: 2 }).then((res) => {
		if (res.data.code === "10000") {
			this.parter = res.data.data["1"];
			this.parterList = res.data.data["2"];
			this.important_partner_mini = res.data.data["3"];
		} else {
			this.$message.error(res.data.msg);
		}
		});
		//大会主题
		video({ type: 1 }).then((res) => {
		if (res.data.code === "10000") {
			this.theme = res.data.data[0];
		} else {
			this.$message.error(res.data.msg);
		}
		});
	},
	methods: {
		videoEnd() {
		let myVideo = document.querySelector(".contentVideo");
		myVideo.currentTime = 0.5;
		},
		change5(Current5) {
		this.Current5 = Current5;
		},
		//手机滑动切换
		touchstartFn5(e) {
		this.startMove5 = e.changedTouches[0].clientX;
		},
		touchendFn5(e) {
		if (e.changedTouches[0].clientX - this.startMove5 > 50) {
			this.changePage5(-1);
		} else if (e.changedTouches[0].clientX - this.startMove5 < -50) {
			this.changePage5(1);
		}
		},
		changePage5(page) {
		if (page > 0) {
			this.$refs.carouselParter5.next();
		} else {
			this.$refs.carouselParter5.prev();
		}
		},
		goAudience() {
		this.$router.push("/Maudience");
		},
		change1(Current1) {
		this.Current1 = Current1;
		},
		change2(Current2) {
		this.Current2 = Current2;
		},
		change3(Current3) {
		this.Current3 = Current3;
		},
		change4(Current4) {
		this.Current4 = Current4;
		},
		//手机滑动切换
		touchstartFn4(e) {
		this.startMove4 = e.changedTouches[0].clientX;
		},
		touchendFn4(e) {
		if (e.changedTouches[0].clientX - this.startMove4 > 50) {
			this.changePage4(-1);
		} else if (e.changedTouches[0].clientX - this.startMove4 < -50) {
			this.changePage4(1);
		}
		},
		changePage4(page) {
		if (page > 0) {
			this.$refs.carouselParter4.next();
		} else {
			this.$refs.carouselParter4.prev();
		}
		},

		//手机滑动切换
		touchstartFn3(e) {
		this.startMove3 = e.changedTouches[0].clientX;
		},
		touchendFn3(e) {
		if (e.changedTouches[0].clientX - this.startMove3 > 50) {
			this.changePage3(-1);
		} else if (e.changedTouches[0].clientX - this.startMove3 < -50) {
			this.changePage3(1);
		}
		},
		changePage3(page) {
		if (page > 0) {
			this.$refs.carouselMap.next();
		} else {
			this.$refs.carouselMap.prev();
		}
		},
		//手机滑动切换
		touchstartFn2(e) {
		this.startMove2 = e.changedTouches[0].clientX;
		},
		touchendFn2(e) {
		if (e.changedTouches[0].clientX - this.startMove2 > 50) {
			this.changePage2(-1);
		} else if (e.changedTouches[0].clientX - this.startMove2 < -50) {
			this.changePage2(1);
		}
		},
		changePage2(page) {
		if (page > 0) {
			this.$refs.carouselParter2.next();
		} else {
			this.$refs.carouselParter2.prev();
		}
		},
		changePage(page) {
		if (page > 0) {
			this.$refs.carousel.next();
		} else {
			this.$refs.carousel.prev();
		}
		},
		//手机滑动切换
		touchstartFn(e) {
		this.startMove = e.changedTouches[0].clientX;
		},
		touchendFn(e) {
		if (e.changedTouches[0].clientX - this.startMove > 50) {
			this.changePage(-1);
		} else if (e.changedTouches[0].clientX - this.startMove < -50) {
			this.changePage(1);
		}
		},
		//轮播图倒计时功能
		countDownTimeFn() {
		if (this.countDownTime.time <= 0) return;
		var time = this.countDownTime.time - 1;

		let daydiff = 24 * 60 * 60;
		let hoursdiff = 60 * 60;
		let minutesdiff = 60;
		let d = Math.floor(time / daydiff); //天数
		let h = Math.floor((time - d * daydiff) / hoursdiff);
		let m = Math.floor((time - d * daydiff - h * hoursdiff) / minutesdiff);
		let s = Math.floor(time - d * daydiff - h * hoursdiff - m * minutesdiff);
		if (d >= 100) {
			this.countDownTime.day1 = Math.floor((d - 100) / 10);
			this.countDownTime.day2 = (d - 100) % 10;
		} else if (d < 10) {
			this.countDownTime.day1 = 0;
			this.countDownTime.day2 = d;
		} else {
			this.countDownTime.day1 = Math.floor(d / 10);
			this.countDownTime.day2 = d % 10;
		}
		if (h < 10) {
			this.countDownTime.hour1 = 0;
			this.countDownTime.hour2 = h;
		} else {
			this.countDownTime.hour1 = Math.floor(h / 10);
			this.countDownTime.hour2 = h % 10;
		}
		if (m < 10) {
			this.countDownTime.min1 = 0;
			this.countDownTime.min2 = m;
		} else {
			this.countDownTime.min1 = Math.floor(m / 10);
			this.countDownTime.min2 = m % 10;
		}
		if (s < 10) {
			this.countDownTime.sec1 = 0;
			this.countDownTime.sec2 = s;
		} else {
			this.countDownTime.sec1 = Math.floor(s / 10);
			this.countDownTime.sec2 = s % 10;
		}
		if (Math.floor(s % 10) == 9) {
			this.countDownTime.secType1 = true;
		}
		if (s == 59) {
			this.countDownTime.minType2 = true;
			if (Math.floor(m % 10) == 9) {
			this.countDownTime.minType1 = true;
			}
		}
		this.countDownTime.secType2 = true;
		setTimeout(() => {
			this.copyTimeFn();
		}, 500);
		this.countDownTime.time = time;
		// console.log(d , h , m , s)
		},
		//旋转翻页倒计时
		copyTimeFn() {
		this.countDownTime.secType1 = false;
		this.countDownTime.secType2 = false;
		this.countDownTime.minType1 = false;
		this.countDownTime.minType2 = false;
		this.countDownTime.hourType1 = false;
		this.countDownTime.hourType2 = false;
		this.countDownTime.dayType1 = false;
		this.countDownTime.dayType2 = false;
		var timeCopy = this.countDownTime.time;
		let daydiff = 24 * 60 * 60;
		let hoursdiff = 60 * 60;
		let minutesdiff = 60;
		let day = Math.floor(timeCopy / daydiff); //天数
		let hour = Math.floor((timeCopy - day * daydiff) / hoursdiff);
		let min = Math.floor(
			(timeCopy - day * daydiff - hour * hoursdiff) / minutesdiff
		);
		let sec = Math.floor(
			timeCopy - day * daydiff - hour * hoursdiff - min * minutesdiff
		);
		if (day >= 100) {
			this.countDownTime.dayCopy1 = Math.floor((day - 100) / 10);
			this.countDownTime.dayCopy2 = (day - 100) % 10;
			this.countDownTime.hundred = true;
		} else if (day < 10) {
			this.countDownTime.dayCopy1 = 0;
			this.countDownTime.dayCopy2 = day;
		} else {
			this.countDownTime.dayCopy1 = Math.floor(day / 10);
			this.countDownTime.dayCopy2 = day % 10;
		}
		if (hour < 10) {
			this.countDownTime.hourCopy1 = 0;
			this.countDownTime.hourCopy2 = hour;
		} else {
			this.countDownTime.hourCopy1 = Math.floor(hour / 10);
			this.countDownTime.hourCopy2 = hour % 10;
		}
		if (min < 10) {
			this.countDownTime.minCopy1 = 0;
			this.countDownTime.minCopy2 = min;
		} else {
			this.countDownTime.minCopy1 = Math.floor(min / 10);
			this.countDownTime.minCopy2 = min % 10;
		}
		if (sec < 10) {
			this.countDownTime.secCopy1 = 0;
			this.countDownTime.secCopy2 = sec;
		} else {
			this.countDownTime.secCopy1 = Math.floor(sec / 10);
			this.countDownTime.secCopy2 = sec % 10;
		}
		},
	},
};
</script>

<style lang="less" scoped>
.Massembly {
	background: #1c1c1c;
	text-align: center;
	padding-bottom: 63px;
	.MvideoBox {
		video {
			top: unset;
			bottom: 0;
		}
	}
}
.partner {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	.partnerBox {
		width: 100%;
		margin-top: 7px;
		position: relative;
		height:331px;
		.content {
		height: 100%;
		overflow: hidden;
		}
		.carousel {
			width: 331px;
			height: 100%;
			margin: 0 auto 0;
			/deep/ .el-carousel__container {
			height: 100%;
			}
		}
		.partnerItem {
			width: 331px;
			height: 100%;
			background: #000000;
			float: left;
			border-radius: 8px;
			img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			}
		}
	}
	.title {
		margin-top: 30px;
		font-size: 20px;
		font-family: YouSheBiaoTiHei;
		color: #ffffff;
		line-height: 26px;
	}
	.title:nth-of-type(1){
		margin-top: 16px;
	}
}

.container {
	padding-bottom: 154px;
}

.videoBox .title1 {
	top: 52px;
}

.videoBox .title2 {
	top: 83px;
}

.videoBox .model {
	background-color: transparent;
}

.headInfo {
	margin-top: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.headInfo img {
	width: 182px;
	height: 40px;
}

.span1 {
	font-size: 22px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 29px;
	margin-top: 16px;
}

.span2 {
	font-size: 14px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 18px;
	margin-top: 10px;
	text-align: center;
}

.span3 {
	font-size: 14px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 18px;
	text-align: center;
}

.theme {
	margin-top: 37px;
}

.theme .title {
	font-size: 22px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 29px;
}

.videoContent {
	width: 331px;
	height: 186px;
	border-radius: 8px;
	margin: 16px auto 0;
}

.videoContent video {
	width: 100%;
	height: 100%;
}

.speaker {
	width: 331px;
	margin: 48px auto 0;
	text-align: center;
	position: relative;
}
.wrapper {
	height: 431px;
	position: relative;
	width: 331px;
	margin: 24px auto 0;
	/deep/ .el-carousel__container {
		height: 100%;
		width: 331px;
	}
	.carousel {
		height: 100%;
		width: 100%;
	}
	.speakerBox {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		img {
			width: 100%;
			height: 100%;
		}
	}
}
.titleName {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 31px;
}

.swiper {
	width: 100%;
	height: 431px;
	margin: 24px auto 0;
}

.speakerBox {
	width: 100%;
	height: 100%;
}

.speakerBox img {
	width: 100%;
	height: 100%;
}

.list {
	width: 331px;
	margin: 27px auto 0;
}

.list img {
	width: 100%;
}

.schedule {
	margin: 70px auto 0;
}

.schedule .content {
	width: 331px;
	margin: 24px auto 0;
}

.schedule .title {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 31px;
}

.timelist .lineCut {
	width: 2px;
	height: 13px;
	opacity: 0.6;
	background: #fff;
	margin-top: 10px;
	margin-bottom: 8px;
	margin-left: 9px;
}

.listBox {
	display: flex;
	align-items: center;
}

.clock {
	font-size: 18px;
	font-family: YouSheBiaoTiHei;
	color: #7FDDE4;
	line-height: 23px;
	display: flex;
	align-items: center;
	width: 207px;
}

.clock img {
	width: 20px;
	height: 20px;
	margin-right: 24px;
}

.stepName {
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #FFFFFF;
	line-height: 20px;
}


.partnerBox {
	position: relative;
}

.carousel {
	width: 331px;
	height: 207px;
	margin: 17px auto 0;
}


.place {
	margin-top: 40px;
	.mapContent {
	position: relative;
	}
	.placeTip {
		font-size: 12px;
		font-family: AlibabaPuHuiTi_2_55_Regular;
		color: #ffffff;
		line-height: 17px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-top: 46px;
		img {
			width: 12px;
			height: 18px;
			margin-right: 8px;
		}
	}
	.carouselMap {
		width: 337px;
		height: 209px;
		margin: 16px auto 0;
		/deep/ .el-carousel__container {
			width: 331px;
			height: 100%;
			margin: 0 auto;
		}
	}
	.content {
		height: 100%;
		overflow: hidden;
		.img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
		}
	}
	.title {
		font-size: 26px;
		font-family: YouSheBiaoTiHei;
		color: #ffffff;
		line-height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 27px;
			height: 26px;
			margin-left: 10px;
		}
	}
}



.parterTitle {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 31px;
	margin-top: 48px;
	text-align: center;
}


.firstParter {
	margin-top: 16px;
}




.countDown {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 16px auto 0;
}

.labelBox {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 14px;
	position: relative;
	font-size: 30px;
	font-family: AlibabaPuHuiTi_2_85_Bold;
	color: #FFFFFF;
	line-height: 36px;
	text-align: center;
}

.countLabel {
	font-size: 14px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 18px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -21px;
	text-align: center;
}

.leftLeaf {
	width: 23px;
	height: 36px;
	background: #333;
	border-radius: 1px;
	position: relative;
	overflow: hidden;
}

.rightLeaf {
	width: 23px;
	height: 36px;
	background: #333;
	border-radius: 1px;
	margin-left: 2px;
	position: relative;
	overflow: hidden;
}

.firstDay {
	margin-right: 2px;
}

.circleBox {
	position: absolute;
	left: -9px;
}

.timeCut {
	width: 4px;
	height: 4px;
	background: #FFFFFF;
	border-radius: 50%;
}

.timeCut:nth-of-type(2) {
	margin-top: 8px;
}

.countDownline {
	width: 100%;
	height: 2px;
	background: #1C1C1C;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 111;
	text-align: center;
}

.rotorLeaf {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	transform-style: preserve-3d;
}

.flipped {
	transition: all 0.5s ease-in-out;
	transform: rotateX(-180deg) translateZ(1px);
}

.rotate {
	background-color: #333;
	height: 18px;
	position: absolute;
	width: 100%;
	overflow: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transform: rotateX(0);
}

.rotate:nth-of-type(1) {
	transform: rotateX(-180deg);
	line-height: 0;
}

.leaf {
	height: 18px;
	position: absolute;
	width: 100%;
	overflow: hidden;
}

.leafBottom {
	bottom: 0;
	line-height: 0;
}

.code {
	margin: 36px auto 0;
	width: 331px;
	height: 349px;
}

.code img {
	width: 100%;
	height: 100%;
}


.titleDes {
	font-size: 16px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 21px;
	margin-top: 4px;
	text-align: center;
}



.info {
	width: 331px;
	margin: 48px auto 0;
}

.infoHead {
	margin: 24px auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.info1 {
	font-size: 16px;
	font-family: YouSheBiaoTiHei;
	color: #7FDDE4;
	line-height: 21px;
}

.info2 {
	font-size: 16px;
	font-family: YouSheBiaoTiHei;
	color: #7FDDE4;
	line-height: 21px;
	margin-top: 6px;
}

.info3 {
	font-size: 16px;
	font-family: YouSheBiaoTiHei;
	color: #7FDDE4;
	line-height: 21px;
	margin-top: 6px;
}

.infoHead img {
	width: 48px;
	height: 38px;
	margin: 22px auto 0;
}

.textBoxContent {
	margin: 24px auto 0;
}

.textBoxContent .desc {
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #AAAAAA;
	line-height: 20px;
	margin-top: 24px;
	position: relative;
	word-break: break-all;
}

.textBoxContent .letterLeft {
	position: absolute;
	top: -6px;
	left: 0;
	width: 20px;
	height: 18px;
}

.letterLefttext {
	text-indent: 30px;
}


.info .name {
	font-size: 12px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #FFFFFF;
	line-height: 18px;
	text-align: right;
}

.info .name .span {
	margin-top: 4px;
}

.name1 {
	margin-top: 4px;
}

.name1:nth-of-type(1) {
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_65_Medium;
	color: #FFFFFF;
	line-height: 21px;
	margin-top: 15px;
}

.typeList {
	width: 331px;
	margin: 89px auto 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.typeList .type {
	font-size: 14px;
	font-family: YouSheBiaoTiHei;
	color: #AAAAAA;
	line-height: 32px;
	width: 161px;
	height: 32px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	text-align: center;
}

.typeList .activeType {
	background: #7FDDE4;
	color: #fff;
}

.letterBottom {
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Bottomname {
	width: 174px;
	font-size: 14px;
	font-family: YouSheBiaoTiHei;
	color: #7FDDE4;
	line-height: 18px;
}

.letterRight {
	width: 150px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

}

.letterRight img {
	width: 20px;
	height: 18px;
}

.letterBottom .line {
	width: 100%;
	height: 4px;
	background: #7FDDE4;
	margin-top: 4px;
	border-radius: 4px;
}

.tese {
	width: 331px;
	margin: 48px auto 0;
}

.tese .title {
	font-size: 22px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 29px;
}

.tese img {
	width: 100%;
	height: 160px;
	margin-top: 16px;
}

.tips {
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #D1D1D1;
	line-height: 24px;
	margin: 16px 0 0;
	white-space: nowrap;
	display: flex;
	align-items: center;
}
.tips:nth-of-type(1){
	width: 162px;
}
.tipLine{
	display: flex;
	flex-wrap: wrap;
}
.circle {
	width: 9px;
	height: 9px;
	background: #FFFFFF;
	border-radius: 50%;
	margin-right: 8px;
}

.mudi {
	width: 331px;
	margin: 48px auto 0;
}

.mudi .title {
	font-size: 22px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 29px;
}

.mudi img {
	width: 100%;
	height: 160px;
	margin-top: 16px;
}

.textContent {
	width: 331px;
	margin: 16px auto 0;
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #D1D1D1;
	line-height: 24px;
	text-align: left;
}


.root {
	margin-top: 62px;
}

.root .title {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 31px;
	margin-bottom: 16px;
}

.typeBox {
	position: relative;
	width: 331px;
	height: 232px;
	margin: 24px auto 0;
}

.typeBox:nth-of-type(1) {
	margin: 0 auto;
}

.typeBox img {
	border-radius: 8px;
	width: 100%;
	height: 100%;
}

.typeBox .typeName {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 48px;
	padding: 0 12px;
	height: 50px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	border: 1px solid #FFFFFF;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
</style>